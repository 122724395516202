import React from 'react';
import Box from '@material-ui/core/Box';
import ContentSection from "../components/ContentSection";
import SEO from "../components/utility/SEO";
import GridContainer from "../components/GridContainer";
import IconDescription from "../components/IconDescription";
import {graphql} from "gatsby";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Auroralib({data}) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicAuroralibPage) return '';
  const { data: pageData } = data.prismicAuroralibPage;

  function handleReverse(index) {
    return index % 2 === 0;
  }

  return (
      <>
        <SEO title={pageData.meta_title ? pageData.meta_title : "Auroralib"}/>
        <Box>
          <ContentSection
              imageBottom
              image={pageData.image ? pageData.image.url : null}
              title={pageData.title}
              subtitle={pageData.subtitle}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}/>
          <GridContainer gridItemProps={{xs:12, md: 4}} style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
            {pageData.feature_cards && pageData.feature_cards.map((iconDescription, index) => {
              return (
                  <IconDescription icon={iconDescription.icon ? iconDescription.icon.url : null}
                                   description={iconDescription.description} key={index}/>
              )
            })}
          </GridContainer>
          {pageData.body && pageData.body.map((content, index) => {
            if (!content.primary) return '';
            return (
                <ContentSection
                    reverse={handleReverse(index)}
                    key={index}
                    url={content.primary.url}
                    image={content.primary.image ? content.primary.image.url : null}
                    title={content.primary.title}
                    subtitle={content.primary.subtitle}
                    button={content.primary.button}
                    style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
                />
            )
          })}
        </Box>
      </>
  );
}

export const auroralibPageQuery = graphql`
    query AuroralibPage($id: String!) {
        prismicAuroralibPage(id: {eq: $id}) {
            data {
                meta_title
                title
                subtitle
                image {
                    url
                }
                feature_cards {
                    description
                    icon {
                        url
                    }
                }
                body {
                    ... on PrismicAuroralibPageBodyContentSection {
                        primary {
                            title
                            subtitle
                            button
                            url
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
